import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function NotFound() {
  return (
  	<Layout>
  	 <h1>Monarchy is inequality. Get back to <Link to="/">anarchy</Link>.</h1>
    </Layout>
  	)
}
